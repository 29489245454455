import React from 'react';
import { Box, Heading, Text, VStack } from '@chakra-ui/react';

const PrivacyPolicy = () => {
    return (
        <Box maxW="800px" mx="auto" p={6} mt={10}>
            <Heading as="h1" size="xl" textAlign="center" mb={6}>
                Privacy Policy
            </Heading>
            <VStack spacing={4} align="start">
                <Text>
                    Welcome to <strong>InsightAnalyze</strong>! Your privacy is important to us, and we are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you use our services.
                </Text>

                <Heading as="h2" size="md" mt={4}>
                    1. Information We Collect
                </Heading>
                <Text>
                    We may collect the following types of information:
                </Text>
                <ul>
                    <li>
                        <strong>Personal Information:</strong> Name, email address, and other details you provide during registration.
                    </li>
                    <li>
                        <strong>Usage Data:</strong> Information about your interactions with our application, such as pages visited, time spent on pages, and other diagnostic data.
                    </li>
                    <li>
                        <strong>Cookies:</strong> Data collected via cookies or similar tracking technologies to enhance your experience.
                    </li>
                </ul>

                <Heading as="h2" size="md" mt={4}>
                    2. How We Use Your Information
                </Heading>
                <Text>
                    We use your information to:
                </Text>
                <ul>
                    <li>Provide and maintain our services.</li>
                    <li>Improve, personalize, and expand our application.</li>
                    <li>Communicate with you, including customer service support and updates.</li>
                    <li>Analyze how you use our application to improve user experience.</li>
                </ul>

                <Heading as="h2" size="md" mt={4}>
                    3. How We Share Your Information
                </Heading>
                <Text>
                    We do not sell your personal information. However, we may share your data with:
                </Text>
                <ul>
                    <li>
                        <strong>Service Providers:</strong> Third-party companies to facilitate our services or perform related services (e.g., analytics, hosting).
                    </li>
                    <li>
                        <strong>Legal Authorities:</strong> When required by law or to protect our legal rights.
                    </li>
                </ul>

                <Heading as="h2" size="md" mt={4}>
                    4. Your Data Protection Rights
                </Heading>
                <Text>
                    Depending on your location, you may have the following rights:
                </Text>
                <ul>
                    <li>Access your personal data.</li>
                    <li>Request corrections or deletion of your personal data.</li>
                    <li>Object to the processing of your data.</li>
                    <li>Withdraw consent where applicable.</li>
                </ul>
                <Text>
                    To exercise these rights, please contact us at{' '}
                    <strong>support@insightanalyze.com</strong>.
                </Text>

                <Heading as="h2" size="md" mt={4}>
                    5. Security of Your Information
                </Heading>
                <Text>
                    We use commercially reasonable methods to protect your personal information. However, no transmission over the internet is 100% secure.
                </Text>

                <Heading as="h2" size="md" mt={4}>
                    6. Updates to This Policy
                </Heading>
                <Text>
                    We may update this Privacy Policy from time to time. Any changes will be posted on this page with a revised "last updated" date.
                </Text>

                <Heading as="h2" size="md" mt={4}>
                    7. Contact Us
                </Heading>
                <Text>
                    If you have any questions about this Privacy Policy, please contact us at:{' '}
                    <strong>support@insightanalyze.com</strong>.
                </Text>
            </VStack>
        </Box>
    );
};

export default PrivacyPolicy;

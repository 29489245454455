import React, { useState, useEffect } from 'react';
import {
  Box,
  Heading,
  List,
  ListItem,
  Button,
  Input,
  VStack,
  IconButton,
  useToast,
  Spinner,
  Text,
  Divider,
  Flex,
  Stack,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import axios from 'axios';

const Sources = () => {
  const [sources, setSources] = useState([]);
  const [newSourceName, setNewSourceName] = useState('');
  const [newSourceUrl, setNewSourceUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingScrape, setLoadingScrape] = useState({});
  const [scrapedArticles, setScrapedArticles] = useState([]);
  const toast = useToast();

  useEffect(() => {
    const fetchSources = async () => {
      try {
        const response = await axios.get('http://localhost:5000/sources');
        setSources(response.data);
      } catch (error) {
        toast({
          title: "Error loading sources",
          description: "Could not fetch sources.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchSources();
  }, [toast]);

  const handleAddSource = async () => {
    if (!newSourceName || !newSourceUrl) {
      toast({
        title: "Error",
        description: "Both source name and URL are required",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    let formattedUrl = newSourceUrl;
    if (!newSourceUrl.startsWith('http://') && !newSourceUrl.startsWith('https://')) {
      formattedUrl = `http://${newSourceUrl}`;
    }

    try {
      const response = await axios.post('http://localhost:5000/sources', {
        name: newSourceName,
        base_url: formattedUrl,
      });

      setSources([...sources, { id: response.data.id, name: newSourceName, base_url: formattedUrl }]);
      setNewSourceName('');
      setNewSourceUrl('');
      toast({
        title: "Source added",
        description: response.data.message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Could not add source",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  // Scrape the source for articles
  const handleScrapeSource = async (source) => {
    setLoadingScrape({ ...loadingScrape, [source.id]: true });
    try {
      const response = await axios.post('http://localhost:5000/scrape', { article_url: source.base_url });
      setScrapedArticles(response.data.articles);
      toast({
        title: "Scraping completed",
        description: `Scraped articles from ${source.name}.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: `Failed to scrape ${source.name}.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoadingScrape({ ...loadingScrape, [source.id]: false });
    }
  };

  return (
    <Box p={5} maxW="800px" mx="auto" borderRadius="lg" boxShadow="md" bg="white">
      <Heading mb={6} textAlign="center" color="teal.600">
        Manage News Sources
      </Heading>

      <VStack spacing={4} mb={6}>
        <Input
          placeholder="Enter source name"
          value={newSourceName}
          onChange={(e) => setNewSourceName(e.target.value)}
        />
        <Input
          placeholder="Enter source base URL"
          value={newSourceUrl}
          onChange={(e) => setNewSourceUrl(e.target.value)}
        />
        <Button colorScheme="teal" width="100%" onClick={handleAddSource}>
          Add Source
        </Button>
      </VStack>

      <Divider mb={6} />

      {loading ? (
        <Flex justify="center">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <List spacing={4}>
          {sources.map((source, index) => (
            <ListItem key={index} borderWidth="1px" borderRadius="lg" p={4} boxShadow="sm">
              <Stack direction="row" justify="space-between" align="center">
                <Box>
                  <a href={source.base_url} target="_blank" rel="noopener noreferrer">
                    <Text fontSize="lg" fontWeight="bold" color="blue.500" _hover={{ textDecoration: 'underline' }}>
                      {source.name}
                    </Text>
                  </a>
                  <Text color="gray.500">{source.base_url}</Text>
                </Box>
                <Stack direction="row" spacing={3}>
                  <Button
                    colorScheme="teal"
                    onClick={() => handleScrapeSource(source)}
                    isLoading={loadingScrape[source.id]}
                  >
                    Scrape
                  </Button>
                  <IconButton
                    icon={<DeleteIcon />}
                    colorScheme="red"
                    variant="outline"
                    aria-label="Delete source"
                    // onClick={() => handleDeleteSource(source.id)}
                  />
                </Stack>
              </Stack>
            </ListItem>
          ))}
        </List>
      )}

      {scrapedArticles.length > 0 && (
        <Box mt={8}>
          <Heading size="md" mb={4}>Scraped Articles</Heading>
          <List spacing={4}>
            {scrapedArticles.map((article, index) => (
              <ListItem key={index} borderWidth="1px" borderRadius="lg" p={4} boxShadow="sm">
                <Text fontWeight="bold">{article.title}</Text>
                <Text>{article.description}</Text>
              </ListItem>
            ))}
          </List>
        </Box>
      )}
    </Box>
  );
};

export default Sources;

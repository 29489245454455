import React, { useEffect, useState } from 'react';
import {
  Box,
  Image,
  Text,
  Flex,
  Grid,
  GridItem,
  Badge,
  Tag,
  TagLabel,
  Divider,
  VStack,
  HStack,
  Skeleton,
  useColorModeValue,
  Button,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FiBookmark, FiShare2 } from 'react-icons/fi';

const NewsArticle = () => {
  const { articleId } = useParams(); // Get the article ID from the URL
  const navigate = useNavigate(); // Navigation hook for dynamic back navigation
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Color mode values for better dark mode support
  const bg = useColorModeValue('gray.50', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'gray.200');
  const badgeColor = article?.clickbait ? 'red.500' : 'green.500';
  const badgeBg = article?.clickbait ? 'red.100' : 'green.100';

  const baseURL = 
        process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_CLICKBAIT_DETECTOR_DEV
        : process.env.REACT_APP_API_CLICKBAIT_DETECTOR_PROD;

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`http://13.60.36.103:8080/news_articles/${articleId}`);
        setArticle(response.data);
      } catch (err) {
        console.log('Error:', err);
        setError('Error fetching article details');
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [articleId]);

  if (loading) {
    return (
      <Box p="5">
        <Skeleton height="40px" />
        <Skeleton height="40px" />
        <Skeleton height="200px" />
        <Skeleton height="20px" />
      </Box>
    );
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  return (
    <Box
      maxW="800px"
      mx="auto"
      p="6"
      bg={bg}
      borderRadius="lg"
      shadow="lg"
      mt={10}
    >
      {/* Back Button */}
      <Button
        onClick={() => navigate(-1)} // Go back to the previous page
        colorScheme="blue"
        mb={4}
      >
        Back
      </Button>

      {/* Article Image */}
      <Image
        src={article.imageUrl}
        alt={article.title}
        borderRadius="md"
        maxH="300px"
        mb={4}
        objectFit="cover"
        w="full"
      />

      {/* Article Header */}
      <VStack spacing={4} align="stretch">
        <Flex justifyContent="space-between" align="center">
          <Text fontSize="2xl" fontWeight="bold" color="blue.500">
            {article.title}
          </Text>
          <HStack>
            <Tooltip label="Save Article" fontSize="sm">
              <IconButton
                aria-label="Save Article"
                icon={<FiBookmark />}
                variant="ghost"
                size="sm"
                colorScheme="blue"
              />
            </Tooltip>
            <Tooltip label="Share Article" fontSize="sm">
              <IconButton
                aria-label="Share Article"
                icon={<FiShare2 />}
                variant="ghost"
                size="sm"
                colorScheme="blue"
              />
            </Tooltip>
          </HStack>
        </Flex>
        <Text fontSize="sm" color={textColor}>
          <b>Published Date:</b> {article.postDate || 'Unknown Date'}
        </Text>
      </VStack>

      <Divider my={4} />

      {/* Clickbait Badge */}
      <HStack>
        <Badge
          color={badgeColor}
          bg={badgeBg}
          p="2"
          borderRadius="md"
          fontSize="md"
        >
          {article.clickbait ? 'Clickbait' : 'Not Clickbait'}
        </Badge>
      </HStack>

      <Divider my={4} />

      {/* Article Details */}
      <Grid templateColumns="repeat(2, 1fr)" gap={4} textColor={textColor}>
        <GridItem>
          <Text>
            <b>Author:</b> {article.author || 'Unknown Author'}
          </Text>
        </GridItem>
        <GridItem>
          <Text>
            <b>Is in Kosovo:</b> {article.in_kosovo ? 'Yes' : 'No'}
          </Text>
        </GridItem>
        <GridItem>
          <Text>
            <b>Locations:</b> {article.locations.join(', ') || 'None'}
          </Text>
        </GridItem>
        <GridItem>
          <Text>
            <b>Title-Description Similarity:</b> {article.similarity ? 'Yes' : 'No'}
          </Text>
        </GridItem>
      </Grid>

      <Divider my={4} />

      {/* Article Description */}
      <VStack align="start" spacing={2}>
        <Text fontSize="md">{article.description}</Text>
        <Divider />
      </VStack>

      {/* Original Article Button */}
      {article.original_url && (
        <Button
          as="a"
          href={article.original_url}
          target="_blank"
          colorScheme="blue"
          mt={4}
        >
          View Original Article
        </Button>
      )}
    </Box>
  );
};

export default NewsArticle;

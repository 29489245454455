import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Box, Flex } from '@chakra-ui/react';
import Home from './pages/Home';
import Profile from './pages/Profile';
import About from './pages/About';
import Contact from './pages/Contact';
import SignIn from './pages/SignIn';
import Register from './pages/Register';
import NotFound from './pages/NotFound';
import Navbar from './components/composites/Navbar';
import Footer from './components/composites/Footer';
import Chat from './pages/Chat';
import Predict from './pages/Predict';
import NewsArticle from './pages/NewsArticle';
import ProtectedRoute from './components/ProtectedRoute';  
import Sources from './pages/Sources';
import Stats from './pages/Stats';
import Welcome from './pages/Welcome';
import Dashboard from './pages/Dashboard';
import ChangePassword from './pages/ChangePassword';
import PrivacyPolicy from './pages/PrivacyPolicy';

const App = () => {
  return (
    <Router>
      <Flex direction="column" height="100vh">
        <Navbar />
        <Box 
          flex="1" 
          // mt="80px"   // Make sure to adjust this for navbar height
          // overflowY="auto" 
          // paddingX={4} 
          // paddingBottom={4}
        >
          <Routes>
            {/* Protected Routes */}
            <Route path="/" element={ 
                <Welcome />
            } />
            <Route path="/news" element={ 
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            } />
              <Route path="/dashboard" element={ 
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } />
             <Route path="/change_password" element={ 
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>
            } />
            <Route 
              path="/profile" 
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route 
              path="/chatbot" 
              element={
                <ProtectedRoute>
                  <Chat />
                </ProtectedRoute>
              }
            />
            <Route 
              path="/privacy&policy" 
              element={
                  <PrivacyPolicy />
              }
            />
            <Route 
              path="/predict" 
              element={
                <ProtectedRoute>
                  <Predict />
                </ProtectedRoute>
              }
            />
             <Route 
              path="/stats" 
              element={
                <ProtectedRoute>
                  <Stats />
                </ProtectedRoute>
              }
            />

            <Route 
              path="/sources" 
              element={
                <ProtectedRoute>
                  <Sources />
                </ProtectedRoute>
              } 
            />

            {/* Public Routes */}
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            
            {/* Prevent logged-in users from accessing SignIn or Register */}
            <Route 
              path="/sign_in" 
              element={
                !localStorage.getItem('user') ? <SignIn /> : <Navigate to="/" />
              } 
            />
            <Route 
              path="/register" 
              element={
                !localStorage.getItem('user') ? <Register /> : <Navigate to="/" />
              } 
            />
            
            <Route path="/article/:articleId" element={<NewsArticle />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
        <Footer />
      </Flex>
    </Router>
  );
};

export default App;

// src/components/Footer.js
import React from 'react';
import { Box, Text, Link } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box
      as="footer"
      width="100%" /* Full width */
      p="4"
      bg="gray.700"
      color="white"
      textAlign="center"
      mt="auto" // This will push the footer to the bottom
    >
      <Text>&copy; 2024 InsightAnalyze. All Rights Reserved.</Text>
      <Link href="/privacy&policy" color="teal.200">
        Privacy Policy
      </Link>
    </Box>
  );
};

export default Footer;

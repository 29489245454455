// src/styles/theme.js
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: 'background.light', // Applies the light background globally
        color: 'gray.800',      // Ensures readable text on light background
      },
    },
  },
  colors: {
    gray: {
      50: '#FDFDFD',  // Almost white, for background elements
      100: '#F0F0F0', // Light gray for backgrounds
      200: '#D9D9D9', // For borders or subtle dividers
      300: '#B0B0B0', // Light gray for text or hover states
      400: '#8C8C8C', // Gray for regular text
      500: '#666666', // Primary gray text
      600: '#404040', // Darker gray for elements
      700: '#262626', // Near black for strong text
      800: '#1A1A1A', // Dark gray for backgrounds
      900: '#0D0D0D', // Darkest shade, nearly black
    },
    teal: {
      50: '#E0FCFF',  // Lightest teal for subtle accents
      100: '#B2F3FF', 
      200: '#81EBFF', 
      300: '#50E3FF',  // Softer teal for lighter designs
      400: '#00D4FF',  // Vibrant teal, new primary
      500: '#00B3D8', 
      600: '#0097AF', 
      700: '#007887',
      800: '#005B5F', 
      900: '#003E37',  // Dark teal for strong contrast
    },
    accent: {
      400: '#FFB800', // Gold accent for special highlights
      500: '#FFD700', // Gold for hover states or accentuation
    },
    background: {
      light: '#F7FAFC', // Light background for the app
      dark: '#1A202C',  // Dark mode background
    },
  },
  fonts: {
    heading: "'Poppins', sans-serif",
    body: "'Inter', sans-serif",  // Inter is modern, easy to read
  },
});

export default theme;

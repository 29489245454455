import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  VStack,
  Avatar,
  Heading,
  Text,
  Divider,
  Grid,
  GridItem,
  Badge,
  useToast,
} from '@chakra-ui/react';
import EditProfile from '../components/organisms/EditProfile';

const Profile = () => {
  const [profile, setProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // Control editing mode
  const toast = useToast();

  useEffect(() => {
    // Fetch user profile from local storage
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setProfile(storedUser);
    } else {
      toast({
        title: 'Error loading profile',
        description: 'Unable to load profile details',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);

  if (!profile) return null; // Wait for profile to load

  return (
    <Box maxW="lg" mx="auto" mt={10} p={8} borderWidth={1} borderRadius="lg" boxShadow="lg" bg="white">
      {!isEditing ? (
        <VStack spacing={6} align="center">
          {/* Profile Picture */}
          <Avatar
            size="2xl"
            src={profile.profile_picture_url || 'https://via.placeholder.com/150'}
            name={`${profile.first_name || ''} ${profile.last_name || ''}`}
            mb={4}
            border="2px solid"
            borderColor="gray.300"
          />

          {/* Name and Role */}
          <Heading as="h2" size="lg">
            {profile.first_name || 'N/A'} {profile.last_name || 'N/A'}
          </Heading>
          <Badge colorScheme="teal" fontSize="0.9em">
            {profile.role ? profile.role.toUpperCase() : 'N/A'}
          </Badge>

          {/* Email */}
          <Text fontSize="md" color="gray.600">
            {profile.email || 'N/A'}
          </Text>

          <Divider borderColor="gray.300" />

          {/* Additional Details in Grid Layout */}
          <Grid templateColumns="repeat(2, 1fr)" gap={4} w="full">
            <GridItem>
              <Text fontWeight="bold">Country:</Text>
              <Text>{profile.country || 'N/A'}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Language:</Text>
              <Text>{profile.language ? profile.language.toUpperCase() : 'N/A'}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Phone Number:</Text>
              <Text>{profile.phone_number || 'N/A'}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Timezone:</Text>
              <Text>{profile.timezone || 'N/A'}</Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Account Active:</Text>
              <Text>
                <Badge colorScheme={'green'}>  
                  {/* {profile.is_active ? 'Active' : 'Inactive'} */}
                  Active
                </Badge>
              </Text>
            </GridItem>
            <GridItem>
              <Text fontWeight="bold">Email Verified:</Text>
              <Text>
                <Badge colorScheme={profile.email_verified ? 'green' : 'red'}>
                  {profile.email_verified ? 'Verified' : 'Unverified'}
                </Badge>
              </Text>
            </GridItem>
            <GridItem colSpan={2}>
              <Text fontWeight="bold">Account Created:</Text>
              <Text>
                {profile.created_at
                  ? new Date(profile.created_at).toLocaleString()
                  : 'N/A'}
              </Text>
            </GridItem>
            <GridItem colSpan={2}>
              <Text fontWeight="bold">Last Updated:</Text>
              <Text>
                {profile.updated_at
                  ? new Date(profile.updated_at).toLocaleString()
                  : 'N/A'}
              </Text>
            </GridItem>
          </Grid>

          {/* Edit Profile Button */}
          <Button onClick={() => setIsEditing(true)} bg="teal" color="white" size="lg" mt={6}>
            Edit Profile
          </Button>
        </VStack>
      ) : (
        // Show EditProfile component when isEditing is true
        <EditProfile user={profile} onClose={() => setIsEditing(false)} />
      )}
    </Box>
  );
};

export default Profile;

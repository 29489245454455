import React from 'react';
import { Box, Text, Button, Flex, Icon } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FiHome } from 'react-icons/fi';

const NotFound = () => {
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      height="100vh" // Full viewport height for centering
      bg="gray.50"
      color="gray.700"
      textAlign="center"
      px={4}
    >
      <Box maxW="600px" textAlign="center" py={8}>
        <Text fontSize="6xl" fontWeight="bold" mb={4} color="teal.500">
          404
        </Text>
        <Text fontSize="lg" mb={6}>
          Sorry, the page you are looking for cannot be found.
        </Text>
        <Button
          as={RouterLink}
          to="/"
          leftIcon={<Icon as={FiHome} />}
          colorScheme="teal"
          size="lg"
          borderRadius="md"
          boxShadow="md"
        >
          Go Back to Home
        </Button>
      </Box>
    </Flex>
  );
};

export default NotFound;

// src/pages/About.js
import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

const About = () => {
  return (
      <Box p="5">
        <Heading as="h1">About Us</Heading>
        <Text mt="4">
          Welcome to InsiGHtAnalyze, your trusted source for news and analysis. Our mission is to provide accurate and insightful content to our readers.
        </Text>
      </Box>

  );
};

export default About;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Switch,
  VStack,
  HStack,
  useToast,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const EditProfile = ({ user, onClose }) => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({
    first_name: '',
    last_name: '',
    profile_picture_url: '',
    country: '',
    phone_number: '',
    language: '',
    timezone: '',
    role: '',
    is_active: false,
  });
  const toast = useToast();

  useEffect(() => {
    if (user) {
      setProfile({
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        profile_picture_url: user.profile_picture_url || '',
        country: user.country || '',
        phone_number: user.phone_number || '',
        language: user.language || '',
        timezone: user.timezone || '',
        role: user.role || '',
        is_active: user.is_active || false,
      });
    }
  }, [user]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };

  const handleSwitchChange = (e) => {
    const { name, checked } = e.target;
    setProfile({ ...profile, [name]: checked });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const baseURL = 
        process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_CLICKBAIT_DETECTOR_DEV
        : process.env.REACT_APP_API_CLICKBAIT_DETECTOR_PROD;

    try {
      const response = await axios.post(
        `http://13.60.36.103:8080/profile`,
        {
          email: user.email,
          ...profile,
        },
        {
          withCredentials: true,
        }
      );

      const updatedUser = response.data.updated_user;
      setProfile(updatedUser);

      localStorage.setItem('user', JSON.stringify(updatedUser));

      toast({
        title: 'Profile updated',
        description: 'Your profile has been successfully updated.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      toast({
        title: 'Error updating profile',
        description:
          error.response?.data?.error || 'Something went wrong. Please try again.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <form onSubmit={handleUpdate}>
        <VStack spacing={4}>
          <FormControl id="first_name" isRequired>
            <FormLabel>First Name</FormLabel>
            <Input
              type="text"
              name="first_name"
              value={profile.first_name}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="last_name" isRequired>
            <FormLabel>Last Name</FormLabel>
            <Input
              type="text"
              name="last_name"
              value={profile.last_name}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="profile_picture_url">
            <FormLabel>Profile Picture URL</FormLabel>
            <Input
              type="url"
              name="profile_picture_url"
              value={profile.profile_picture_url}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="country">
            <FormLabel>Country</FormLabel>
            <Input
              type="text"
              name="country"
              value={profile.country}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="phone_number">
            <FormLabel>Phone Number</FormLabel>
            <Input
              type="text"
              name="phone_number"
              value={profile.phone_number}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="language">
            <FormLabel>Language</FormLabel>
            <Input
              type="text"
              name="language"
              value={profile.language}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="timezone">
            <FormLabel>Timezone</FormLabel>
            <Input
              type="text"
              name="timezone"
              value={profile.timezone}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl id="is_active">
            <FormLabel>Active</FormLabel>
            <Switch
              name="is_active"
              isChecked={profile.is_active}
              onChange={handleSwitchChange}
            />
          </FormControl>

          <HStack spacing={4} justify="flex-end" mt={4}>
            <Button
              colorScheme="blue"
              onClick={() => navigate('/change_password')} // Navigate to change password page
            >
              Change Password
            </Button>
            <Button onClick={onClose} colorScheme="gray" variant="outline">
              Back
            </Button>
            <Button type="submit" colorScheme="teal">
              Save
            </Button>
          </HStack>
        </VStack>
      </form>
    </Box>
  );
};

export default EditProfile;

import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    // Check if user data exists in localStorage to verify authentication
    const user = localStorage.getItem('user');

    // If user is not authenticated, redirect to the sign-in page
    if (!user) {
        return <Navigate to="/sign_in" replace />;
    }

    // If authenticated, render the children (protected content)
    return children;
};

export default ProtectedRoute;

import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, Flex, Input, Button, VStack, HStack, Text, useToast } from '@chakra-ui/react';
import { ArrowRightIcon } from '@chakra-ui/icons';
import sendSound from '../assets/audio/send_sound.mp3';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const chatEndRef = useRef(null);
  const toast = useToast();

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const getCurrentTime = () => {
    const date = new Date();
    return `${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userInput.trim()) return;

    const newMessage = { sender: 'user', text: userInput, time: getCurrentTime() };

    // Set messages and play sound
    setMessages([...messages, newMessage]);
    setUserInput('');
    new Audio(sendSound).play();

    setIsTyping(true);

    // Handle the chat response
    handleChatResponse(userInput);
  };

  const handleChatResponse = async (userInput) => {
    try {
      const response = await axios.post('http://localhost:5000/chat', { message: userInput });
      const botMessage = response.data.response;
  
      const details = response.data.details;
      if (details) {
        const summary = response.data.summary;
  
        // Combine the bot message and the article analysis into one message
        const fullMessage = (
          <Box>
            <Text>{botMessage}</Text> {/* Initial bot response */}
            <br />
            <Box p={4} bg="gray.50" rounded="md" boxShadow="sm" mt={4}>
              <Text fontWeight="bold" fontSize="lg" mb={2}>📰 Article Analysis</Text>
              <hr />
              <Text fontWeight="bold">📊 Title:</Text>
              <Text color="gray.700" mb={2}>{details.title}</Text>
              <Text fontWeight="bold">🌍 Translated Title:</Text>
              <Text color="gray.700" mb={2}>{details.translated_title}</Text>
              <Text fontWeight="bold">📍 Identified Locations:</Text>
              <Text color="gray.700" mb={2}>{details.locations.length > 0 ? details.locations.join(', ') : 'None'}</Text>
              <Text fontWeight="bold">🏞 Is in Kosovo:</Text>
              <Text color={details.is_in_kosovo ? "green.500" : "red.500"}>
                {details.is_in_kosovo ? 'Yes' : 'No'}
              </Text>
              <Text fontWeight="bold" mt={2}>⚠️ Clickbait Analysis:</Text>
              <Text color={details.clickbait_analysis === "Clickbait" ? "red.500" : "green.500"}>
                {details.clickbait_analysis || 'Not Clickbait'}
              </Text>
              <Text fontWeight="bold" mt={2}>🔑 Title and Description Similarity:</Text>
              <Text color={details.same_meaning ? "green.500" : "red.500"}>
                {details.same_meaning ? 'Yes' : 'No'}
              </Text>
              <Text fontWeight="bold" mt={2}>Summary:</Text>
              <Text color="gray.700">{summary}</Text>
            </Box>
          </Box>
        );
  
        // Set a single message combining both parts
        setMessages((prevMessages) => [...prevMessages, { sender: 'bot', text: fullMessage, time: getCurrentTime() }]);
      } else {
        setMessages((prevMessages) => [...prevMessages, { sender: 'bot', text: botMessage, time: getCurrentTime() }]);
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', text: 'Sorry, something went wrong. Please try again.', time: getCurrentTime() }
      ]);
      toast({
        title: "Error",
        description: "Something went wrong. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsTyping(false);
    }
  };
  

  return (
    <Flex direction="column" width="100%" height="100%" maxW="760px" maxH="850px" mx="auto" my={4} p={4} borderWidth={1} borderRadius="lg" boxShadow="lg" bg="white">
      <VStack spacing={4} flex="1" overflowY="auto" p={4} bg="gray.100" borderRadius="md" height="600px">
        {messages.map((msg, index) => (
          <Flex key={index} alignSelf={msg.sender === 'user' ? 'flex-end' : 'flex-start'} bg={msg.sender === 'user' ? 'blue.600' : 'gray.300'} color={msg.sender === 'user' ? 'white' : 'black'} p={3} borderRadius="lg" maxW="80%" boxShadow="sm">
            <VStack align="start">
              <Text>{msg.text}</Text>
              <Text fontSize="xs" color={msg.sender === 'user' ? 'white' : 'gray.500'} >{msg.time}</Text>
            </VStack>
          </Flex>
        ))}
        {isTyping && (
          <Flex alignSelf="flex-start" bg="gray.300" p={3} borderRadius="lg">
            <HStack spacing="2">
              <Box bg="gray.500" w="8px" h="8px" borderRadius="full" />
              <Box bg="gray.500" w="8px" h="8px" borderRadius="full" />
              <Box bg="gray.500" w="8px" h="8px" borderRadius="full" />
            </HStack>
          </Flex>
        )}
        <div ref={chatEndRef} />
      </VStack>
      <form onSubmit={handleSubmit}>
        <HStack spacing={3} mt={4}>
          <Input
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder="Type a message..."
            variant="filled"
            size="md"
            bg="gray.50"
            borderColor="gray.300"
            _focus={{ borderColor: 'blue.500' }}
            _hover={{ borderColor: 'gray.400' }}
          />
          <Button type="submit" colorScheme="teal" rightIcon={<ArrowRightIcon />} size="md" _hover={{ bg: 'teal.600' }}>
            Send
          </Button>
        </HStack>
      </form>
    </Flex>
  );
};

export default Chat;

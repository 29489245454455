import React from 'react';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Avatar,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useDisclosure,
  Link as ChakraLink,
  Text,
} from '@chakra-ui/react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import logo from '../../assets/images/logo4.png';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Safely retrieve the user from localStorage
  const user = (() => {
    try {
      const storedUser = localStorage.getItem('user');
      // Ensure storedUser is not undefined or malformed
      if (!storedUser || storedUser === 'undefined') {
        return null;
      }
      return JSON.parse(storedUser); // Parse only valid JSON strings
    } catch (error) {
      console.error('Failed to parse user from localStorage:', error);
      return null;
    }
  })();

  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate('/sign_in');
    window.location.reload();
  };

  // Define links based on user's authentication and role
  const links = user
    ? [
        { path: '/', label: 'Home' },
        { path: '/news', label: 'News' },
        { path: '/predict', label: 'Predict' },
        { path: '/contact', label: 'Contact' },
        { path: '/stats', label: 'Stats' },
        ...(user.role === 'administrator'
          ? [{ path: '/dashboard', label: 'Dashboard' }]
          : []), // Add Dashboard link only for administrators
      ]
    : [
        { path: '/', label: 'Home' },
        { path: '/sign_in', label: 'Sign In' },
        { path: '/register', label: 'Register' },
      ];

  const activeLink = links.find((link) => link.path === location.pathname);

  return (
    <Box as="nav" bg="gray.100" color="black" boxShadow="md" position="sticky" top="0" zIndex="1000">
      <Flex align="center" justify="space-between" px="4" py="2">
        <RouterLink to="/">
          <img src={logo} alt="Logo" style={{ width: '90px', height: 'auto' }} />
        </RouterLink>

        <HStack display={{ base: 'none', md: 'flex' }} spacing="8">
          {links.map((link) => (
            <ChakraLink
              key={link.path}
              as={RouterLink}
              to={link.path}
              fontSize="lg"
              fontWeight="500"
              color={location.pathname === link.path ? 'white' : 'gray.700'}
              bg={location.pathname === link.path ? 'teal' : 'transparent'}
              px={2}
              py={1}
              rounded="md"
              _hover={{ bg: 'teal.50', color: 'teal' }}
              _focus={{ outline: 'none' }}
            >
              {link.label}
            </ChakraLink>
          ))}
          {user && (
            <>
              <Avatar
                size="sm"
                src={user.profile_picture_url || ''}
                name={`${user.first_name} ${user.last_name}`}
                cursor="pointer"
                onClick={() => navigate('/profile')}
              />
              <Button
                onClick={handleLogout}
                bg="red.500"
                color="white"
                size="sm"
                borderRadius="5px"
                px="4"
                py="2"
                fontWeight="bold"
                _hover={{ bg: 'red.700' }}
              >
                Logout
              </Button>
            </>
          )}
        </HStack>

        <Flex display={{ base: 'flex', md: 'none' }} align="center">
          {activeLink && (
            <Text
              fontSize="md"
              fontWeight="500"
              color="white"
              bg="teal"
              px={2}
              py={1}
              rounded="md"
              mr="2"
            >
              {activeLink.label}
            </Text>
          )}
          <IconButton
            aria-label="Open Menu"
            icon={<HamburgerIcon />}
            onClick={onOpen}
            variant="ghost"
          />
        </Flex>
      </Flex>

      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Menu</DrawerHeader>
          <DrawerBody>
            {links.map((link) => (
              <ChakraLink
                key={link.path}
                as={RouterLink}
                to={link.path}
                fontSize="lg"
                fontWeight="500"
                color={location.pathname === link.path ? 'teal' : 'gray.700'}
                bg={location.pathname === link.path ? 'teal.50' : 'transparent'}
                px={2}
                py={1}
                rounded="md"
                _hover={{ bg: 'teal.100', color: 'teal' }}
                display="block"
                mb="2"
                onClick={onClose}
              >
                {link.label}
              </ChakraLink>
            ))}
            {user && (
              <Button
                onClick={handleLogout}
                bg="red.500"
                color="white"
                size="md"
                width="100%"
                mt="4"
                _hover={{ bg: 'red.700' }}
              >
                Logout
              </Button>
            )}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Navbar;

import React from 'react';
import {
  Box,
  Heading,
  Text,
  Image,
  Stack,
  Tag,
  TagLabel,
  Flex,
  Badge,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { keyframes } from '@emotion/react';

const redGlow = keyframes`
  0% { box-shadow: 0 0 5px rgba(255, 0, 0, 0.5); }
  50% { box-shadow: 0 0 20px rgba(255, 0, 0, 1); }
  100% { box-shadow: 0 0 5px rgba(255, 0, 0, 0.5); }
`;

const greenGlow = keyframes`
  0% { box-shadow: 0 0 5px rgba(0, 255, 0, 0.5); }
  50% { box-shadow: 0 0 20px rgba(0, 255, 0, 1); }
  100% { box-shadow: 0 0 5px rgba(0, 255, 0, 0.5); }
`;

const ArticleCard = ({ title, description, imageUrl, prediction, author, postDate }) => {
  const borderColor = prediction === 'Clickbait' ? 'red.500' : 'green.500';
  const glowAnimation = prediction === 'Clickbait' ? `${redGlow} 2s infinite` : `${greenGlow} 2s infinite`;

  return (
    <Box
      position="relative"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p="5"
      bg="gray.50"
      boxShadow="md"
      _hover={{ boxShadow: 'lg', animation: glowAnimation }}
      borderColor={borderColor}
      borderStyle="solid"
    >
      <Image src={imageUrl} alt={title} borderRadius="md" maxH="200px" objectFit="cover" />

      <Stack mt="4" spacing="3">
        <Flex justify="space-between" align="center">
          <Heading size="md" color="teal.700">
            {title}
          </Heading>
          <Badge colorScheme={prediction === 'Clickbait' ? 'red' : 'green'}>
            {prediction}
          </Badge>
        </Flex>
        <Text color="gray.700" noOfLines={3}>
          {description}
        </Text>
        <Text color="gray.500" fontSize="sm">
          Author: {author || 'Unknown'}
        </Text>
        <Text color="gray.500" fontSize="sm">
          Published: {new Date(postDate).toLocaleDateString()}
        </Text>
        <Tag size="lg" mt={2} colorScheme="teal">
          <TagLabel>Read More</TagLabel>
        </Tag>
      </Stack>
    </Box>
  );
};

export default ArticleCard;

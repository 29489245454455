import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  Heading,
  Text,
  useToast,
  Icon,
} from '@chakra-ui/react';
import { FiSend } from 'react-icons/fi';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Simulating email send request (replace this with your backend API)
      await new Promise((resolve) => setTimeout(resolve, 1000));

      toast({
        title: 'Message sent!',
        description: 'We will get back to you shortly.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Something went wrong. Please try again later.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      maxW="700px"
      mx="auto"
      mt="12"
      p="8"
      bg="white"
      borderRadius="lg"
      shadow="lg"
      _hover={{
        transform: 'scale(1.01)',
        transition: 'transform 0.3s ease-in-out',
      }}
    >
      <Heading
        as="h1"
        size="xl"
        textAlign="center"
        mb="6"
        bgGradient="linear(to-r, teal.500, green.500)"
        bgClip="text"
      >
        Get in Touch
      </Heading>
      <Text mb="8" fontSize="md" color="gray.600" textAlign="center">
        We'd love to hear from you. Fill out the form below, and we'll respond
        as soon as possible.
      </Text>
      <form onSubmit={handleSubmit}>
        <VStack spacing="5">
          <FormControl isRequired>
            <FormLabel fontWeight="bold">Name</FormLabel>
            <Input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              focusBorderColor="teal.400"
              borderRadius="full"
              shadow="sm"
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontWeight="bold">Email</FormLabel>
            <Input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              focusBorderColor="teal.400"
              borderRadius="full"
              shadow="sm"
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontWeight="bold">Message</FormLabel>
            <Textarea
              name="message"
              placeholder="Your Message"
              rows="4"
              value={formData.message}
              onChange={handleChange}
              focusBorderColor="teal.400"
              borderRadius="lg"
              shadow="sm"
            />
          </FormControl>

          <Button
            type="submit"
            isLoading={loading}
            loadingText="Sending"
            colorScheme="teal"
            size="lg"
            borderRadius="full"
            shadow="md"
            rightIcon={<Icon as={FiSend} />}
          >
            Send Message
          </Button>
        </VStack>
      </form>
    </Box>
  );
};

export default Contact;

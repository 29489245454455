import React from "react";
import { useNavigate } from "react-router-dom";
import girlWithLaptop from "../assets/images/girl_with_laptop.jpg"; 
import statsImage from "../assets/images/stats.jpg";
import clickbaitWarning from "../assets/images/check_clickbait_with_warning.jpg";

const storedUser = localStorage.getItem('user');
const currentUser = storedUser && storedUser !== "undefined" ? JSON.parse(storedUser) : null;



const WelcomePage = () => {
  const navigate = useNavigate();

  // Function to handle navigation to either register or news page based on user status
  const handleNavigation = () => {
    if (currentUser) {
      navigate("/news"); // Redirect to the news page if the user is logged in
    } else {
      navigate("/register"); // Redirect to the register page if no user is logged in
    }
  };

  return (
    <div style={{ fontFamily: "'Roboto', sans-serif", color: "#333" }}>
      {/* Hero Section */}
      <section
        style={{
          background: "linear-gradient(135deg, #B2F5EA, #81E6D9, #319795)", // Soft teal gradient
          color: "#fff",
          padding: "5rem 2rem",
          textAlign: "center",
          borderBottom: "1px solid #ddd",
        }}
      >
        <h1
          style={{
            fontSize: "4rem",
            marginBottom: "1rem",
            color: "#f0f0f0", // Bright text for contrast
            fontWeight: "bold"
          }}
        >
          {currentUser?.first_name ? `Welcome back, ${currentUser.first_name}!` : "Welcome to Insight Analyze"}
        </h1>
        <p
          style={{
            fontSize: "1.2rem",
            marginBottom: "2rem",
            maxWidth: "600px",
            margin: "0 auto",
            color: "#E6FFFA", // Muted light teal for softer contrast
          }}
        >
          Analyze news with ease and avoid clickbait. Stay informed and focus on the facts.
        </p>
        <br />
        <div style={{ display: "flex", justifyContent: "center", gap: "1rem" }}>
          <button
            onClick={handleNavigation} // Updated to call handleNavigation
            style={{
              padding: "0.8rem 2rem",
              fontSize: "1rem",
              backgroundColor: "#81E6D9", // Lighter teal for buttons
              color: "#1A4040", // Deep teal for text
              border: "none",
              borderRadius: "25px",
              cursor: "pointer",
              transition: "background-color 0.3s ease",
            }}
            onMouseOver={(e) => (e.target.style.backgroundColor = "#4FD1C5")}
            onMouseOut={(e) => (e.target.style.backgroundColor = "#81E6D9")}
          >
            Get Started
          </button>

          {!currentUser && (
            <button
              onClick={() => navigate("/sign_in")}
              style={{
                padding: "0.8rem 2rem",
                fontSize: "1rem",
                backgroundColor: "#319795", // Primary teal
                color: "#FFFFFF", // White text
                border: "none",
                borderRadius: "25px",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#2C7A7B")}
              onMouseOut={(e) => (e.target.style.backgroundColor = "#319795")}
            >
              Sign In
            </button>
          )}
        </div>
      </section>

      {/* Features Section */}
      <section style={{ padding: "4rem 2rem", backgroundColor: "#f9f9f9" }}>
        <h2 style={{ textAlign: "center", fontSize: "2.5rem", marginBottom: "2rem" }}>
          Why Choose InsightAnalyze?
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "2rem",
            flexWrap: "wrap",
          }}
        >
          <div style={{ maxWidth: "300px", textAlign: "center" }}>
            <img
              src={clickbaitWarning}
              alt="Feature 1"
              style={{ maxWidth: "200px", marginBottom: "1rem", borderRadius: "15px", justifySelf: 'center' }}
            />
            <h3>Clickbait Detection</h3>
            <p>
              Uncover clickbait with our AI-powered algorithms and ensure you only read the
              most credible news.
            </p>
          </div>
          <div style={{ maxWidth: "300px", textAlign: "center" }}>
            <img
              src={statsImage}
              alt="Feature 2"
              style={{ maxWidth: "200px", marginBottom: "1rem", borderRadius: "15px", justifySelf: "center" }}
            />
            <h3>Content Insights</h3>
            <p>
              Dive deep into content analysis with detailed insights that help you stay
              informed.
            </p>
          </div>
          <div style={{ maxWidth: "300px", textAlign: "center" }}>
            <img
              src={girlWithLaptop}
              alt="Feature 2"
              style={{ maxWidth: "200px", marginBottom: "1rem", borderRadius: "15px", justifySelf: "center" }}
            />
            <h3>Custom Feeds</h3>
            <p>
              Personalize your experience by selecting your favorite news sources.
            </p>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section style={{ padding: "4rem 2rem", backgroundColor: "#fff" }}>
        <h2 style={{ textAlign: "center", fontSize: "2.5rem", marginBottom: "2rem" }}>
          What Our Users Say
        </h2>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "2rem",
            flexWrap: "wrap",
          }}
        >
          <div
            style={{
              maxWidth: "300px",
              textAlign: "center",
              border: "1px solid #ddd",
              borderRadius: "5px",
              padding: "1.5rem",
            }}
          >
            <p>
              "InsightAnalyze has completely transformed the way I consume news. I can now
              avoid clickbait and focus on what matters."
            </p>
            <p style={{ fontWeight: "bold", marginTop: "1rem" }}>- Jane Doe</p>
          </div>
          <div
            style={{
              maxWidth: "300px",
              textAlign: "center",
              border: "1px solid #ddd",
              borderRadius: "5px",
              padding: "1.5rem",
            }}
          >
            <p>
              "A fantastic platform! It's user-friendly and gives me the information I need
              without the noise."
            </p>
            <p style={{ fontWeight: "bold", marginTop: "1rem" }}>- John Smith</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WelcomePage;

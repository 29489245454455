import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Flex,
  Icon,
  Tooltip,
  Grid,
  VStack
} from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon, TimeIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js';

// Register required elements with Chart.js
ChartJS.register(ArcElement, ChartTooltip, Legend);

const Stats = () => {
  const [statsData, setStatsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const baseURL = 
        process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_API_CLICKBAIT_DETECTOR_DEV
        : process.env.REACT_APP_API_CLICKBAIT_DETECTOR_PROD;

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await axios.get(`http://13.60.36.103:8080/stats`);
        setStatsData(response.data);
      } catch (err) {
        setError('Failed to fetch stats.');
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (loading) {
    return <Spinner size="lg" />;
  }

  if (error) {
    return <Text color="red.500">{error}</Text>;
  }

  if (!statsData) {
    return <Text>No stats data available.</Text>;
  }

  const {
    total_articles,
    correct_classifications,
    incorrect_classifications,
    accuracy_percentage,
    incorrect_percentage,
    last_updated,
    most_common_source,
    top_3_sources
  } = statsData;

  const pieData = {
    labels: ['Correct', 'Incorrect'],
    datasets: [
      {
        label: 'Classification Breakdown',
        data: [correct_classifications, incorrect_classifications],
        backgroundColor: ['#38A169', '#E53E3E'], // Same color as the labels for correct and incorrect
        hoverBackgroundColor: ['#2F855A', '#C53030'],
      },
    ],
  };

  return (
    <Box
      p="6"
      width="100%"
      maxW="1200px"
      mx="auto"
      mt="10"
      bg="gray.50"
      boxShadow="lg"
      borderRadius="lg"
    >
      {/* Header Section */}
      <Text
        fontSize="2xl"
        mb="6"
        fontWeight="bold"
        color="teal.600"
        textAlign="center"
      >
        Statistics Dashboard
      </Text>

      <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} gap="6">
        {/* Stats Section */}
        <Box>
          <Grid gap="6">
            <Stat
              bg="teal.100"
              p="6"
              borderRadius="md"
              boxShadow="sm"
              _hover={{ boxShadow: 'md', transform: 'scale(1.03)', transition: '0.2s' }}
            >
              <StatLabel fontWeight="bold" color="teal.700">
                Total Articles
              </StatLabel>
              <StatNumber fontSize="2xl" color="teal.800">
                {total_articles}
              </StatNumber>
            </Stat>

            <Stat
              bg="green.100"
              p="6"
              borderRadius="md"
              boxShadow="sm"
              _hover={{ boxShadow: 'md', transform: 'scale(1.03)', transition: '0.2s' }}
            >
              <StatLabel fontWeight="bold" color="green.700">
                Correct Classifications
              </StatLabel>
              <Flex align="center" mt="1">
                <StatNumber fontSize="2xl" color="green.800">
                  {correct_classifications}
                </StatNumber>
                <Icon as={CheckCircleIcon} color="green.500" ml="2" boxSize="5" />
              </Flex>
              <StatHelpText fontSize="xs" color="green.600">
                {accuracy_percentage.toFixed(2)}% Accuracy
              </StatHelpText>
            </Stat>

            <Stat
              bg="red.100"
              p="6"
              borderRadius="md"
              boxShadow="sm"
              _hover={{ boxShadow: 'md', transform: 'scale(1.03)', transition: '0.2s' }}
            >
              <StatLabel fontWeight="bold" color="red.700">
                Incorrect Classifications
              </StatLabel>
              <Flex align="center" mt="1">
                <StatNumber fontSize="2xl" color="red.800">
                  {incorrect_classifications}
                </StatNumber>
                <Icon as={WarningIcon} color="red.500" ml="2" boxSize="5" />
              </Flex>
              <StatHelpText fontSize="xs" color="red.600">
                {incorrect_percentage.toFixed(2)}% Incorrect
              </StatHelpText>
            </Stat>
          </Grid>
        </Box>

        {/* Pie Chart Section */}
        <Box textAlign="center">
          <Text fontSize="md" fontWeight="bold" color="teal.600" mb="4">
            Classification Breakdown
          </Text>
          <Pie data={pieData} />
        </Box>
      </Grid>

      {/* Footer Section */}
      <Flex
        mt="8"
        justifyContent="center"
        alignItems="center"
        direction="column"
        textAlign="center"
      >
       

        {/* Top 3 Sources */}
        <Box mt="6" width="100%">
          <Text fontSize="md" fontWeight="bold" color="teal.600" mb="4">
            Top 3 Sources
          </Text>
          <VStack align="start" spacing="2" color="gray.700">
            {top_3_sources && top_3_sources.map(([source, count], index) => (
              <Box key={index} p="4" bg="gray.100" borderRadius="md" shadow="sm" w="100%">
                <Text fontWeight="bold">{index + 1}. {source}</Text>
                <Text fontSize="sm">Articles: {count}</Text>
              </Box>
            ))}
          </VStack>
        </Box>

        <Tooltip label={`Last updated: ${new Date(last_updated).toLocaleString()}`} hasArrow>
          <Flex alignItems="center" mt="4">
            <TimeIcon color="teal.500" boxSize="4" mr="2" />
            <Text fontSize="sm" color="gray.600">
              Last updated: {new Date(last_updated).toLocaleDateString()}
            </Text>
          </Flex>
        </Tooltip>
      </Flex>
    </Box>
  );
};

export default Stats;

// src/styles/globalStyles.js
import { Global } from '@emotion/react';

const GlobalStyles = () => (
  <Global
    styles={`
      /* Custom Scrollbar Styles */
      ::-webkit-scrollbar {
        width: 10px;
      }
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #00A4A4; /* Primary teal from your palette */
        border-radius: 10px;
        border: 3px solid #f1f1f1;
      }
      ::-webkit-scrollbar-thumb:hover {
        background-color: #006B6B; /* Darker teal on hover */
      }
    `}
  />
);

export default GlobalStyles;
